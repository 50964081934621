import React, { useMemo } from 'react';
import { useWindowScroll, useWindowSize } from 'react-use';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, PageProps } from 'gatsby';
import styled, { css } from 'styled-components';
import Layout from '~/components/layout';
import SEO from '~/components/seo';
import TitleWithCatchCopy from '~/components/TitleWithCatchCopy';
import Title from '~/components/Title';
import RoundedButton from '~/components/RoundedButton';
import {
  colors,
  Description,
  DescriptionWrapper,
  Section,
  media,
  easings,
  commonKeyframes,
} from '~/styles';
import { useIntersection } from '~/hooks';
import topImg from '~/assets/images/top.jpg';
import LogoTypeWhiteSvg from '~/assets/svgs/logotype_white.svg';

const PALLARAX_START_HEIGHT_RATE = 0.5;
const TOP_VISUAL_HEIGHT_RATE = 0.9;

const IndexPage: React.VFC<PageProps<GatsbyTypes.IndexPageQuery>> = ({
  data: { setting, recruitImg },
}) => {
  const { t } = useTranslation();
  const { y } = useWindowScroll();
  const { height } = useWindowSize();
  const [isAboutIntersection, aboutIntersectionRef] = useIntersection();
  const [isWorksIntersection, worksIntersectionRef] = useIntersection();
  const [isRecruitIntersection, recruitIntersectionRef] = useIntersection();

  const rate = useMemo(() => {
    const position = y / (height * TOP_VISUAL_HEIGHT_RATE);

    if (position < 0) {
      return 0;
    }

    return position <= 1
      ? 1 - (1 - y / (height * TOP_VISUAL_HEIGHT_RATE)) ** 3
      : position;
  }, [y, height]);

  const pallaraxRate = useMemo(
    () => (y < 2 * height ? y / (height * PALLARAX_START_HEIGHT_RATE) : 2.0),
    [y, height],
  );

  return (
    <>
      <SEO title="Index" />
      <Layout>
        <TopVisualWrapper
          style={
            {
              '--top-visual-rate': rate,
              '--top-pallarax-rate': pallaraxRate,
            } as React.CSSProperties
          }
          isPallarax={pallaraxRate > 1}
        >
          <TopVisualImgWrapper>
            <TopVisualImg src={topImg} />
            <LogoWrapper>
              <LogoTypeWrapper>
                <LogoType />
              </LogoTypeWrapper>
              <LogoCatchCopy>
                PROVIDE SAFE AND RELIABLE TECHNOLOGY
              </LogoCatchCopy>
            </LogoWrapper>
          </TopVisualImgWrapper>
        </TopVisualWrapper>
        <Section ref={aboutIntersectionRef}>
          <TitleWithCatchCopy
            title="index.about.title"
            catchCopy="index.about.catchCopy"
            catchSub="index.about.catchSub"
            byKey
            isIntersection={isAboutIntersection}
          />
          <DescriptionWrapper showAnim isIntersection={isAboutIntersection}>
            <Description>{t('index.about.description')}</Description>
            <RoundedButton text={t('common.seeMore')} to="/about" />
          </DescriptionWrapper>
        </Section>
        <WorksWrapper ref={worksIntersectionRef}>
          <Title
            title="index.works.title"
            byKey
            isIntersection={isWorksIntersection}
          />
          <WorksList>
            <WorksItem isIntersection={isWorksIntersection}>
              <WorksLink to="/works" state={{ tag: 'MOVIE' }}>
                <WorksThumbnail
                  image={
                    setting?.movie?.thumbnail?.asset?.gatsbyImageData as any
                  }
                  alt="MOVIE"
                />
                <WorksButton>{t('index.works.movie')}</WorksButton>
              </WorksLink>
            </WorksItem>
            <WorksItem isIntersection={isWorksIntersection}>
              <WorksLink to="/works" state={{ tag: 'GAME' }}>
                <WorksThumbnail
                  image={
                    setting?.game?.thumbnail?.asset?.gatsbyImageData as any
                  }
                  alt="GAME"
                />
                <WorksButton>{t('index.works.game')}</WorksButton>
              </WorksLink>
            </WorksItem>
            <WorksItem isIntersection={isWorksIntersection}>
              <WorksLink to="/works" state={{ tag: 'CM' }}>
                <WorksThumbnail
                  image={setting?.cm?.thumbnail?.asset?.gatsbyImageData as any}
                  alt="CM"
                />
                <WorksButton>{t('index.works.cm')}</WorksButton>
              </WorksLink>
            </WorksItem>
            <WorksItem isIntersection={isWorksIntersection}>
              <WorksLink to="/works" state={{ tag: 'EVENT' }}>
                <WorksThumbnail
                  image={
                    setting?.event?.thumbnail?.asset?.gatsbyImageData as any
                  }
                  alt="EVENT"
                />
                <WorksButton>{t('index.works.event')}</WorksButton>
              </WorksLink>
            </WorksItem>
          </WorksList>
        </WorksWrapper>
        <Section ref={recruitIntersectionRef}>
          <TitleWithCatchCopy
            title="index.recruit.title"
            catchCopy="index.recruit.catchCopy"
            catchSub="index.recruit.catchSub"
            byKey
            isIntersection={isRecruitIntersection}
          />
          <DescriptionWrapper showAnim isIntersection={isRecruitIntersection}>
            <ImageWrapper>
              <StyledGatsbyImage
                image={recruitImg?.childImageSharp?.gatsbyImageData ?? null}
                alt="recruit thumbnail"
              />
            </ImageWrapper>
            <Description>{t('index.recruit.description')}</Description>
            <RoundedButton text={t('common.seeMore')} to="/recruit" />
          </DescriptionWrapper>
        </Section>
      </Layout>
    </>
  );
};

const TopVisualWrapper = styled.section<{ isPallarax: boolean }>`
  position: relative;
  height: 150vh;
  width: 100%;

  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    background-color: ${colors.white};
    opacity: calc(1 - var(--top-visual-rate));
  }

  ${({ isPallarax }) =>
    isPallarax &&
    css`
      transform: translateY(calc((var(--top-pallarax-rate) - 1) * 20vh));
    `}
`;

const TopVisualImgWrapper = styled.div`
  position: sticky;
  inset: 0 0 auto;
  margin: 0 auto;
  height: 92vh;
  width: 100%;
  margin-bottom: 8vh;
  overflow: hidden;
`;

const TopVisualImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: calc(1 - var(--top-visual-rate));
  transform: scale(calc(1 + var(--top-visual-rate)));

  animation: ${commonKeyframes.fadeIn} 1.2s ${easings.easeOutCubic};
`;

const LogoWrapper = styled.div`
  position: absolute;
  inset: 0;
  margin: auto;
  display: grid;
  grid-gap: 24px;
  justify-items: center;
  align-content: center;

  ${media.mdDown} {
    grid-gap: 12px;
  }
`;

const LogoTypeWrapper = styled.div`
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
`;

const LogoType = styled.img.attrs({ src: LogoTypeWhiteSvg })`
  height: 120px;
  transform: translateY(110%);
  animation: ${commonKeyframes.fadeIn} 0.75s ${easings.easeOutCubic} 0.6s
      forwards,
    ${commonKeyframes.slideIn} 0.75s ${easings.easeOutCubic} 0.6s forwards;

  ${media.lgDown} {
    height: 80px;
  }

  ${media.mdDown} {
    height: auto;
    width: 80%;
  }
`;

const LogoCatchCopy = styled.p`
  letter-spacing: 0.2em;
  text-align: center;
  opacity: 0;
  animation: ${commonKeyframes.fadeIn} 1.2s ${easings.easeOutCubic} 1.35s
    forwards;

  ${media.mdDown} {
    max-width: 80%;
    font-size: 12px;
    letter-spacing: 0.1em;
  }
`;

const WorksWrapper = styled(Section)`
  grid-gap: 72px;
  max-width: none;
`;

const WorksList = styled.ul`
  display: grid;
  grid-gap: 5px;
  width: 100%;

  ${media.mdUp} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const WorksItem = styled.li<{ isIntersection: boolean }>`
  position: relative;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  transform: translateY(5%);

  &::before {
    content: '';
    width: 100%;
    padding-top: 80vh;
    display: block;
  }

  ${media.mdDown} {
    &::before {
      padding-top: 56.25%;
    }
  }

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.75s ${easings.easeOutCubic}
          forwards,
        ${commonKeyframes.slideIn} 0.75s ${easings.easeOutCubic} forwards;
      &:nth-child(1) {
        animation-delay: 0.6s;
      }
      &:nth-child(2) {
        animation-delay: 0.75s;
      }
      &:nth-child(3) {
        animation-delay: 0.9s;
      }
      &:nth-child(4) {
        animation-delay: 1.05s;
      }
    `}
`;

const WorksButton = styled.button`
  text-align: center;
  line-height: 1;
  padding: 12px 0;
  font-size: 24px;
  width: 190px;
  border-radius: 999px;
  border: 2px solid ${colors.white};
  transition: transform 0.6s ${easings.easeOutCubic};
  z-index: 1;

  ${media.mdDown} {
    font-size: 16px;
    width: 50%;
  }
`;

const WorksThumbnail = styled(GatsbyImage)`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.6s ${easings.easeOutCubic};

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    background-color: ${colors.black};
    opacity: 0.4;
    transition: opacity 0.6s ${easings.easeOutCubic};
  }
`;

const WorksLink = styled(Link)`
  position: absolute;
  inset: 0;
  display: grid;
  justify-items: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${colors.lightGray};

  &:hover {
    ${WorksButton} {
      transition: transform 0.3s ${easings.easeOutCubic};
      transform: scale(0.96);
    }

    ${WorksThumbnail} {
      transition: transform 0.45s ${easings.easeOutCubic};
      transform: scale(1.02);

      &::after {
        opacity: 0.1;
      }
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 56.25%;
  }
`;

const StyledGatsbyImage = styled(GatsbyImage).attrs({
  src: '../assets/images/recruit.jpg',
  alt: 'recruit thumbnail',
})`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`;

export default IndexPage;

export const query = graphql`
  query IndexPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    setting: sanityIndexSetting {
      movie {
        thumbnail {
          asset {
            gatsbyImageData
          }
        }
      }
      game {
        thumbnail {
          asset {
            gatsbyImageData
          }
        }
      }
      cm {
        thumbnail {
          asset {
            gatsbyImageData
          }
        }
      }
      event {
        thumbnail {
          asset {
            gatsbyImageData
          }
        }
      }
    }
    recruitImg: file(
      relativePath: { eq: "recruit.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
